import styled from 'styled-components'
import Header from './components/Header'
import HeroBanner from './components/HeroBanner'
import CountDown from './components/CountDown'
import GameAndExperiences from './components/GemeAndExperiences'
import HowToEarn from './components/HowToEarn'
import Airdrop from './components/Airdrop'
import Footer from './components/Footer'
import Play from './components/Play'

const LandingPage = () => {
  return (
    <Wrapper className=''>
      <Header />
      <HeroBanner />
      <CountDown />
      <GameAndExperiences />
      <HowToEarn />
      <Airdrop />
      <Play />
      <Footer />
    </Wrapper>
  )
}

export default LandingPage

const Wrapper = styled.div`
  color: #fff;
  background-color: #000000;
`
