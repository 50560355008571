import { Button as AntdButton, Drawer } from 'antd'
import styled from 'styled-components'
import { MenuOutlined } from '@ant-design/icons'
import { useState } from 'react'
import DrawerMenu from './DrawerMenu'

const LINKS = [
  {
    title: 'HOME',
    link: '/',
  },
  {
    title: 'OVERVIEW',
    link: '/about',
  },
  {
    title: 'NFT’s',
    link: '/contact',
  },
  {
    title: 'TOKENOMICS',
    link: '/tokenomics',
  },
  {
    title: 'COMMUNITY',
    link: '/community',
  },
]

const Header = () => {
  const [open, setOpen] = useState(false)

  return (
    <Wrapper className='w-full'>
      <div className='content container flex items-center justify-between w-full'>
        <div className='flex items-center'>
          <div className='logo'>
            <img src='/images/logo.png' alt='logo' />
            <p>Zeus88</p>
          </div>
          <div className='links flex items-center'>
            {LINKS.map((link) => (
              <a href={link?.link} key={link?.link}>
                {link.title}
              </a>
            ))}
            <div className='language-btn'>EN</div>
          </div>
        </div>

        <div className='connect-wallet'>
          <Button ghost size='large'>
            Connect Wallet
          </Button>
        </div>

        <MenuIcon onClick={() => setOpen(true)} />
      </div>

      <DrawerMenu visible={open} onClose={() => setOpen(false)} links={LINKS} />
    </Wrapper>
  )
}

export default Header

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 16px 0;
  z-index: 1;

  @media screen and (max-width: 768px) {
    padding: 0;
  }

  .content {
    > div {
      gap: 32px;

      .logo {
        display: flex;
        align-items: center;
        img {
          width: 120px;
          height: 120px;

          @media (max-width: 768px) {
            width: 80px;
            height: 80px;
          }
        }
        p {
          font-size: 32px;
          font-weight: 700;
          margin-left: 6px;

          @media (max-width: 768px) {
            font-size: 24px;
          }
        }
      }

      .links {
        gap: 32px;
        & > div {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }

        .language-btn {
          border: 1px solid #fff;
          border-radius: 12px;
          padding: 10px;
          text-transform: uppercase;
          font-size: 18px;
          font-weight: 500;
        }

        @media screen and (max-width: 1024px) {
          display: none;
        }

        @media screen and (max-width: 1280px) {
          gap: 24px;
        }
      }
    }

    .connect-wallet {
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }
`
const Button = styled(AntdButton)`
  border-radius: 12px;
  > span {
    font-size: 18px;
    font-weight: 500;
  }
`

const MenuIcon = styled(MenuOutlined)`
  font-size: 24px;
  cursor: pointer;
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;
  }
`
