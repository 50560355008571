import styled from 'styled-components'

const QUICK_LINKS = [
  {
    title: 'About us',
    link: '/about',
  },
  {
    title: 'Game intro',
    link: '/game-intro',
  },
  {
    title: 'How to earn',
    link: '/how-to-earn',
  },
  {
    title: 'Airdrop',
    link: '/airdrop',
  },
  {
    title: 'Collection',
    link: '/collection',
  },
]

const JOIN_WITH_US = [
  {
    title: 'Chart',
    link: '/chart',
  },
  {
    title: 'Enter game',
    link: '/enter-game',
  },
  {
    title: 'Document',
    link: '/document',
  },
  {
    title: 'Guilde',
    link: '/guilde',
  },
]

const SOCIALS = [
  {
    title: 'Twitter',
    link: 'https://twitter.com',
    icon: '/images/icons/x-icon.png',
  },
  {
    title: 'Telegram',
    link: 'https://telegram.com',
    icon: '/images/icons/telegram-icon.png',
  },
]

const Footer: React.FC = () => {
  return (
    <Wrapper>
      <div className='container'>
        <div className='head'>
          <div className='logo-block'>
            <img src='/images/logo.png' alt='logo' />
            <p>
              Collect and train a diverse roster of legendary heroes, each with unique abilities and playstyles. Battle in a
              dynamic arena and earn
            </p>
          </div>
          <div className='right-side'>
            <div className='quick-link list-link'>
              <h3>Quick Links</h3>
              <div className='list'>
                {QUICK_LINKS.map((item, index) => (
                  <a key={index} href={item.link}>
                    {item.title}
                  </a>
                ))}
              </div>
            </div>
            <div className='join-with-us list-link'>
              <h3>Join with us</h3>
              <div className='list'>
                {JOIN_WITH_US.map((item, index) => (
                  <a key={index} href={item.link}>
                    {item.title}
                  </a>
                ))}
              </div>
            </div>
            <div className='social list-link'>
              <h3>Social</h3>
              <div>
                <div className='list-social'>
                  {SOCIALS.map((item, index) => (
                    <a key={index} href={item.link}>
                      <img src={item.icon} alt={item.title} />
                    </a>
                  ))}
                </div>

                <div className='wrap-input'>
                  <input type='email' placeholder='Your email address' />
                  <button>
                    <span>Send</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='bottom'>
          <div className='arrow-down-outline' />
          <p>©2024 Zeus88 | All Reserved</p>
        </div>
      </div>
    </Wrapper>
  )
}

export default Footer

const Wrapper = styled.div`
  padding: 60px 0;
  padding-top: 0;

  .container {
    display: flex;
    flex-direction: column;
    gap: 44px;

    .head {
      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      gap: 65px;

      @media screen and (max-width: 640px) {
        display: flex;
        flex-direction: column;
      }

      .right-side {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-column: span 8 / span 8;
        gap: 32px;

        @media screen and (max-width: 1024px) {
          grid-column: span 6 / span 6;
        }

        @media screen and (max-width: 768px) {
          grid-column: span 12 / span 12;
        }

        > div {
          @media screen and (max-width: 1024px) {
            grid-column: span 2 / span 2;
          }

          @media screen and (max-width: 768px) {
            grid-column: span 1 / span 1;
          }

          @media screen and (max-width: 640px) {
            grid-column: span 2 / span 2;
          }
        }

        .social {
          grid-column: span 2 / span 2;

          @media screen and (max-width: 1024px) {
            grid-column: span 4 / span 4;
          }

          @media screen and (max-width: 768px) {
            grid-column: span 2 / span 2;
          }

          @media screen and (max-width: 640px) {
            grid-column: span 4 / span 4;
          }
        }
      }

      .logo-block {
        display: flex;
        flex-direction: column;
        gap: 16px;
        grid-column: span 4 / span 4;

        @media screen and (max-width: 1024px) {
          grid-column: span 6 / span 6;
        }

        @media screen and (max-width: 768px) {
          grid-column: span 12 / span 12;
        }

        img {
          width: 124px;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
        }
      }

      .social {
        .list-social {
          display: flex;
          gap: 16px;
          align-items: center;
        }

        > div {
          display: flex;
          flex-direction: column;
          gap: 24px;
        }

        .wrap-input {
          padding: 8px 24px;
          display: flex;
          align-items: center;
          background: linear-gradient(90deg, rgba(21, 21, 21, 0) 0%, #151515 54%, rgba(21, 21, 21, 0) 100%);
          border-width: 0.5px, 0px, 0.5px, 0px;

          border-style: solid;
          border-image-slice: 1;
          border-width: 2px;
          border-image-source: linear-gradient(269.77deg, rgba(0, 0, 0, 0) 4.14%, #edbd4b 49.89%, rgba(0, 0, 0, 0) 95.64%);
          width: 100%;

          input {
            background: transparent;
            border: none;
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            text-align: left;
            flex-grow: 1;

            &::placeholder {
              color: #fff;
            }

            &:focus {
              outline: none;
            }
          }

          button {
            cursor: pointer;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            border: none;
            background-color: transparent;
            padding: 12px 24px;
            color: #000;
            font-size: 20px;
            font-weight: 700;
            line-height: 25.53px;
            text-align: center;

            background-image: url('/images/landing/gold-btn.png');

            @media screen and (max-width: 1024px) {
              font-size: 16px;
            }
          }
        }
      }

      .list-link {
        display: flex;
        flex-direction: column;
        gap: 24px;

        h3 {
          font-size: 20px;
          font-weight: 600;
          line-height: 22px;
          text-align: left;
          color: #edbd4b;
        }

        .list {
          display: flex;
          flex-direction: column;
          gap: 12px;

          a {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            text-align: left;
          }
        }
      }
    }

    .bottom {
      border-top: 1px solid;
      border-image-slice: 1;
      border-width: 1px;
      border-image-source: linear-gradient(269.77deg, rgba(237, 189, 75, 0) 4.14%, #edbd4b 56.29%, rgba(237, 189, 75, 0) 95.64%);
      position: relative;

      .arrow-down-outline {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #edbd4b;
        position: relative;
        top: -1px;
        left: 50%;
        transform: translateX(-50%);
      }

      .arrow-down-outline::before {
        content: '';
        position: absolute;
        top: -10px;
        left: -8px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid black;
      }

      p {
        margin-top: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.92px;
        letter-spacing: 0.5px;
        text-align: center;
        color: #ffffffa8;
      }
    }
  }
`
