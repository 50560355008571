import styled from 'styled-components'

const Tabs: React.FC = () => {
  return (
    <Wrapper>
      <div className='active'>Character collection</div>
      <div>Weapon collection</div>
    </Wrapper>
  )
}

export default Tabs

const Wrapper = styled.div`
  background: #0000003d;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: fit-content;
  padding: 4px;
  border-radius: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.24);
  backdrop-filter: blur(8px);

  > div {
    padding: 12px 24px;
    border-radius: 10px;
    font-size: 24px;
    font-weight: 700;
    line-height: 30.72px;
    text-align: left;
    cursor: pointer;
    word-spacing: -8px;

    &.active {
      box-shadow: 1px 2px 4px 0px #ffffff inset;
      background: #016fd5;
      border-top: 1px solid rgba(255, 255, 255, 0.12);
    }
  }
`
