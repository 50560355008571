import styled from 'styled-components'

const HowToEarn: React.FC = () => {
  return (
    <Wrapper>
      <img className='badge' src='/images/landing/badge.png' alt='Badge' />

      <div className='container'>
        <div className='title'>
          <h1>How To Earn ?</h1>
          <p>
            Collect and train a diverse roster of legendary heroes, each with unique abilities and playstyles. Battle in a dynamic
            arena and earn blockchain-powered rewards.
          </p>
        </div>

        <div className='content'>
          <img src='/images/landing/zeus-sit-on-gold.png' alt='Zeus siting on gold' />
          <img src='/images/landing/how-to-earn-step.png' alt='How to earn step' />
        </div>
      </div>
    </Wrapper>
  )
}

export default HowToEarn

const Wrapper = styled.div`
  background-image: url('/images/landing/how-earn-bg.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 82px;
  position: relative;

  border-top: 2px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(90deg, #000000 0%, #ffeb68 50%, #000000 100%);

  @media screen and (max-width: 640px) {
    padding-top: 64px;
  }

  .badge {
    position: absolute;
    width: 310px;
    top: -26px;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: 640px) {
      width: 164px;
      top: -14px;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 77px;

    @media screen and (max-width: 640px) {
      gap: 53px;
    }

    .title {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 12px;

      h1 {
        font-family: Lora;
        font-size: 62px;
        font-weight: 700;
        line-height: 79.13px;
        text-align: center;
        background: linear-gradient(167.47deg, #fdfffd 33.85%, #fffa8e 65.47%, #fffb9f 72.42%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        word-spacing: -20px;

        @media screen and (max-width: 640px) {
          font-size: 32px;
          line-height: 41px;
          word-spacing: -12px;
        }
      }

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        text-align: center;
        max-width: 539px;

        @media screen and (max-width: 640px) {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    .content {
      display: grid;
      grid-template-columns: 1fr 1fr;

      @media screen and (max-width: 640px) {
        display: flex;
        flex-direction: column-reverse;
        gap: 83px;
      }

      img {
        &:first-child {
          width: 673px;

          @media screen and (max-width: 640px) {
            width: 100%;
            transform: scale(1.2);
            transform-origin: bottom;
          }
        }

        &:last-child {
          width: 597px;

          @media screen and (max-width: 640px) {
            width: 100%;
          }
        }
      }
    }
  }
`
