import styled from 'styled-components'

const HeroBanner = () => {
  return (
    <Wrapper className=''>
      <div className='radial' />
      <div className='content container'>
        <div className='left-side'>
          <p className='title'>FREE - TO - PLAY • FREE - TO - EARN </p>
          <div className='colorful-text'>
            <p>WELCOME TO </p>
            <p>ZEUS88</p>
          </div>
          <p className='description'>
            Unleash your inner hero in the ultimate blockchain-powered battle arena. Collect, train, and battle with legendary
            characters to earn rewards and dominate the leaderboards.
          </p>

          <div className='button-ground flex items-center'>
            <button className='gold-button'>
              <span>Play Game</span>
            </button>
            <button className='silver-button'>
              <span>Learn More</span>
            </button>
          </div>
        </div>
        <div className='right-side'>
          <img src='/images/landing/Zeus88.png' alt='zeus' />
        </div>
      </div>
    </Wrapper>
  )
}

export default HeroBanner

const Wrapper = styled.div`
  background-image: url('/images/landing/wild_zeus_background.gif');
  background-repeat: no-repeat;
  background-size: cover;
  aspect-ratio: 16 / 10;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 768px) {
    aspect-ratio: initial;
    padding-top: 124px;
    padding-bottom: 32px;
  }

  .radial {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: radial-gradient(92.81% 165% at 3.16% 77.64%, #000000 4.2%, rgba(0, 0, 0, 0.66) 39.68%, rgba(0, 0, 0, 0) 71.84%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

    @media screen and (max-width: 768px) {
      background: radial-gradient(200% 245% at 3.16% 77.64%, #000000 4.2%, rgba(0, 0, 0, 0.66) 39.68%, rgba(0, 0, 0, 0) 71.84%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    }
  }

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
    }

    @media screen and (max-width: 640px) {
      grid-template-columns: 1fr;
      gap: 0px;
    }

    .left-side {
      display: flex;
      justify-content: center;
      flex-direction: column;

      @media screen and (max-width: 768px) {
        display: block;
        grid-column: 1/3;
      }

      .title {
        font-family: Lora;
        font-size: 18px;
        font-weight: 500;
        line-height: 23.04px;
        text-align: left;
        color: #ffffffa8;

        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
        @media screen and (max-width: 1024px) {
          font-size: 16px;
        }
      }

      .colorful-text {
        margin-top: 12px;

        @media screen and (max-width: 768px) {
          margin-top: 8px;
        }

        > p {
          &:first-child {
            font-family: Lora;
            font-size: 62px;
            font-weight: 700;
            line-height: 79.13px;
            text-align: left;

            @media screen and (max-width: 1024px) {
              font-size: 32px;
              line-height: 62px;
            }
          }

          &:last-child {
            font-family: Montagu Slab;
            font-size: 138px;
            font-weight: 700;
            line-height: 176.13px;
            text-align: left;
            background: linear-gradient(167.47deg, #fdfffd 33.85%, #fffa8e 65.47%, #fffb9f 72.42%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media screen and (max-width: 1024px) {
              font-size: 84px;
              line-height: 84px;
            }
          }
        }
      }

      .description {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;

        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
      }

      .button-ground {
        display: flex;
        align-items: center;
        gap: 32px;
        margin-top: 32px;

        @media screen and (max-width: 640px) {
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          margin-top: 16px;
        }

        button {
          cursor: pointer;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          border: none;
          background-color: transparent;
          padding: 22px 46px;
          color: #000;
          font-size: 26px;
          font-weight: 700;

          @media screen and (max-width: 1024px) {
            font-size: 16px;
          }

          &.gold-button {
            background-image: url('/images/landing/gold-btn.png');
          }

          &.silver-button {
            background-image: url('/images/landing/silver-btn.png');
          }
        }
      }
    }

    .right-side {
      img {
        width: 100%;
        transform: translateY(32px);
      }

      @media screen and (max-width: 768px) {
        display: block;
        grid-column: 3/4;
        transform: scale(1.5);
        transform-origin: left;
      }

      @media screen and (max-width: 640px) {
        display: none;
      }
    }
  }
`
