import styled from 'styled-components'

const GameAndExperiences = () => {
  return (
    <Wrapper>
      <div className='container'>
        <div className='first-block'>
          <div className='left-side'>
            <div>
              <h2>Games And Experiences</h2>
              <p>
                Collect and train a diverse roster of legendary heroes, each with unique abilities and playstyles. Battle in a
                dynamic arena and earn blockchain-powered rewards.
              </p>
            </div>

            <button className='gold-button'>
              <span>Play Game</span>
            </button>
          </div>

          <img src='/images/landing/game-and-experience-img.png' alt='game and experiences' />
        </div>

        <div className='second-block'>
          <img src='/images/landing/tap-function.png' alt='Tap Function' />
          <img src='/images/landing/pvp-mode.png' alt='PVP Mode' />
          <img src='/images/landing/kill-boss.png' alt='Kill Boss' />
        </div>
      </div>
    </Wrapper>
  )
}

export default GameAndExperiences

const Wrapper = styled.div`
  background-image: url('/images/landing/game-and-experiences-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  padding-top: 70px;
  padding-bottom: 70px;

  .container {
    display: flex;
    flex-direction: column;
    gap: 44px;

    .first-block {
      display: flex;
      align-items: center;
      gap: 101px;

      @media screen and (max-width: 1024px) {
        gap: 64px;
      }
      @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        gap: 32px;
      }

      .left-side {
        display: flex;
        flex-direction: column;
        gap: 24px;

        > div {
          display: flex;
          flex-direction: column;
          gap: 12px;

          h2 {
            font-size: 62px;
            font-weight: 700;
            line-height: 79.13px;
            text-align: left;
            background: linear-gradient(167.47deg, #fdfffd 33.85%, #fffa8e 65.47%, #fffb9f 72.42%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            word-spacing: -20px;
          }

          p {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
          }
        }

        button {
          align-self: flex-start;
          background-image: url('/images/landing/gold-btn.png');
          cursor: pointer;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          border: none;
          background-color: transparent;
          padding: 22px 46px;
          color: #000;
          font-size: 26px;
          font-weight: 700;

          @media screen and (max-width: 1024px) {
            font-size: 16px;
          }
        }
      }

      img {
        width: 604px;
        flex-shrink: 0;

        @media screen and (max-width: 1024px) {
          width: 500px;
        }
        @media screen and (max-width: 640px) {
          width: 100%;
        }
      }
    }

    .second-block {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 32px;

      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }

      @media screen and (max-width: 640px) {
        grid-template-columns: 1fr;
      }

      img {
        width: 100%;
      }
    }
  }
`
